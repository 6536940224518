const PartnerAreaLogo = () => {

    return (
        <div className="flex items-center pl-2.5 mb-5">
            <img src="/partner-area-icon.png"
                className="h-6 mr-2 sm:h-10" alt="Partner-area Ícone" />
        </div>
    );
};

export default PartnerAreaLogo;