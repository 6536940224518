import { ChangeEvent, FormEvent, useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import AgrolendLogo from '../components/ions/AgrolendLogo';
import { AuthContext } from '../security/AuthContext';

const Login = () => {

	const auth = useContext(AuthContext);
	const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState<String | null>(null);
	const [signedIn, setSignedIn] = useState(false);

	const handleUserNameInput = (event: ChangeEvent<HTMLInputElement>) => {
		setUsername(event.target.value);
	}

	const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setSignedIn(true);
		if (!username) {
			setError('Usuário precisa ser preenchido!');
			setSignedIn(false);
			return;
		}

		if (!password) {
			setError('Senha precisa ser preenchida!');
			setSignedIn(false);
			return;
		}

		if (username && password) {
			const isLogged = await auth.signin(username, password);
			if (isLogged) {
				navigate("/home");
			}
			setSignedIn(false);
			setError('Usuário ou senha inválidos');
		}
	}

	return (
		<div className="bg-white">
			<form
				onSubmit={handleSubmit}
				className="max-w-sm mx-auto mt-8 bg-neutral-50 shadow-md rounded px-8 pt-6 pb-8 mb-4"
			>
				{error ?
					<div className="bg-red-100 border-b border-t border-red-400 text-red-700 px-4 py-3 mb-3" role="alert">
						<p className="text-sm">{error}</p>
					</div>
					: null
				}
				<div className={`mb-4 ${(error) ? 'border-red-500' : ''}`}>
					<div className='grid justify-items-center'>
						<AgrolendLogo className='self-center' />
						<p className='text-base pb-2 py-12'>Entre com o nome do usuário e a senha</p>
					</div>

					<label htmlFor="usernameSignInInput" className="block text-gray-700 text-sm font-bold mb-2">
						Username
					</label>
					<input
						className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `}
						type="text"
						name="username"
						id="usernameSignInInput"
						onChange={handleUserNameInput}
					/>
				</div>
				<div className={`mb-4 ${(error) ? 'border-red-500' : ''}`}>
					<label htmlFor="passwordSignInInput" className="block text-gray-700 text-sm font-bold mb-2">
						Senha
					</label>
					<input
						className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `}
						type="password"
						name="password"
						id="passwordSignInInput"
						onChange={handlePasswordInput}
					/>
				</div>
				<div className="mb-4">
					<input
						defaultChecked
						type="checkbox"
						className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
						id="rememberMeSignInInput"
					//onChange={changeAuthStorageConfiguration}
					/>
					<label htmlFor="rememberMeSignInInput" className="ml-2 text-gray-700">
						Lembrar-se de mim
					</label>
				</div>
				<button
					disabled={signedIn}
					type="submit"
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full disabled:opacity-30"
				>
					{signedIn ? 'Entrando...' : 'Entrar'}
				</button>
			</form>
			<p className="text-center text-gray-500 text-xs">
				&copy;2024 Agrolend. All rights reserved.
			</p>
		</div>
	);
}

export default Login;