import { FC } from "react";

interface AgrolendLogoProps {
    className?: string;
}


const AgrolendLogo: FC<AgrolendLogoProps> = ({ className }) => {
    return (
        <div className="flex items-center">
            <img src="/logo-login.png"
                className={`flex items-center ${className}`} alt="Logo da Área da Parceira" />
        </div>
    );
};

export default AgrolendLogo;