import React, { useContext } from 'react';
import { GrLogout } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../security/AuthContext';

const UserData: React.FC = () => {

	const navigate = useNavigate();
	const auth = useContext(AuthContext);

	let username = '';
	let email = '';
	let roles: string[] = [];
	let name = '';

	const user = auth.user;
	if (user) {
		username = user.username;
		email = user.email;
		name = user.name;
		roles = user.roles;
	}

	const handleLogout = () => {
		if (window.confirm("Deseja realmente efetuar o logout do sistema?")) {
			navigate('/logout');
		}
	};

	return (
		<div className="border-t p-2">

			<div className="grid grid-rows-3 grid-flow-col">

				<div className="text-sm font-semibold max-w-64 truncate">{name}</div>
				<div className="text-sm max-w-64 truncate">{roles}</div>
				<div className="text-sm max-w-64 truncate">{email}</div>

				<div className="row-span-3 flex items-center justify-center">
					<button
						className="text-slate-500 p-2 rounded-full transition ease-in duration-200 hover:text-white z-90 hover:bg-slate-500 hover:shadow"
						onClick={handleLogout}
					>
						<GrLogout />
					</button>
				</div>
			</div>
		</div>
	);
};

export default UserData;