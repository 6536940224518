const amplifyConfig = {
    Auth: {
        region: `${process.env.REACT_APP_AWS_COGNITO_REGION}`,
        userPoolId: `${process.env.REACT_APP_AWS_USER_POOLS_ID}`,
        userPoolWebClientId: `${process.env.REACT_APP_AWS_POOLS_WEB_CLIENT_ID}`,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
            domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
            scope: ['aws.cognito.signin.user.admin'],
            redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGN_IN}`,
            redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGN_OUT}`,
            clientId: `${process.env.REACT_APP_AWS_POOLS_WEB_CLIENT_ID}`,
            responseType: 'token'
        }   
    }
};

export default amplifyConfig;