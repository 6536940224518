import { Outlet } from 'react-router-dom';

import Layout from './components/templates/Layout';
import "@preact/signals-react/auto";

function App() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default App;