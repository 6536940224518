import React, { useState } from 'react';

interface UserImageProps {
    imageUrl: string;
    label: string;
    onIconClick?: () => void;
}

const UserImage: React.FC<UserImageProps> = ({ imageUrl, label, onIconClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="relative flex items-center flex-shrink-0 p-2">
            <img
                className={`w-10 h-10 rounded-lg shadow-md ${isHovered ? 'cursor-pointer' : ''} `}
                src={imageUrl}
                alt={label}
                onClick={onIconClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            <span className="sr-only">{label}</span>
        </div>
    );
};

export default UserImage;