import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import App from "./App";
import NotFound from "./pages/errors/NotFound";
import AccessDenied from "./pages/errors/AccessDenied";
import InternalError from "./pages/errors/InternalError";
import Logout from "./pages/Logout";

import NetworkError from "./pages/errors/NetworkError";
import RequireAuth from "./security/RequireAuth";
import Login from "./pages/Login";

const AppRoutes = () => {

    const ADMIN = 'PARTNER_ADMIN';
    const OPERATOR = 'PARTNER_OPERATOR';

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <RequireAuth roles={[ADMIN, OPERATOR]}>
                        <App />
                    </RequireAuth>} errorElement={<InternalError />}>
                    <Route path="home" element={<Home />} />
                    <Route path="denied" element={<AccessDenied />} />
                    <Route path="error" element={<InternalError />} />
                    <Route path="network-error" element={<NetworkError />} />
                    <Route path="logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<NotFound />} />
                <Route path="login" element={<Login />} />
            </Routes>
        </BrowserRouter >
    );
};

export default AppRoutes;