import { useState, useEffect, useContext } from "react";
import PartnerAreaIcon from "../ions/PartnerAreaIcon";

import { FaHome, FaHandshake, FaFileContract, FaHandHoldingUsd } from "react-icons/fa"
import { BiCog } from "react-icons/bi"
import { PiPlantBold } from "react-icons/pi"
import Tooltip from "../atoms/Tooltip";
import UserImage from "../atoms/UserImage";
import SectionSidebar from "../molecules/SectionSidebar";
import { Link } from "react-router-dom";
import { AuthContext } from "../../security/AuthContext";

export const BATCH_ANALYSIS_LIST = "/analysis/batch-analysis";

const Sidebar = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const [currentSidebarTab, setCurrentSidebarTab] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user)
			setIsAdmin(user.roles.includes("ADMIN"));

		const watchScreen = () => {
			if (window.innerWidth <= 1024) {
				setSidebarOpen(false);
			}
		};

		window.addEventListener("resize", watchScreen);

		return () => {
			window.removeEventListener("resize", watchScreen);
		};
	}, [useContext]);

	const toggleSidebar = (tab: any) => {
		if (isSidebarOpen && currentSidebarTab === tab) {
			setSidebarOpen(false);
		} else {
			setSidebarOpen(true);
			setCurrentSidebarTab(tab);
		}
	};

	const toggleUserMenu = () => {
		if (isSidebarOpen) setSidebarOpen(false);
		else {
			toggleSidebar("tabUser");
		}
	};

	return (
		<aside className="flex flex-shrink-0 transition-all h-screen">
			{isSidebarOpen && (
				<div
					onClick={() => setSidebarOpen(false)}
					className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
				></div>
			)}
			{isSidebarOpen && <div className="fixed inset-y-0 z-10 w-16 bg-white"></div>}

			<nav
				aria-label="Options"
				className={`z-20 flex-col items-center flex-shrink-0 hidden w-16 py-4 bg-slate-100 border-r-2 shadow-md sm:flex border-black-100`}
			>
				<PartnerAreaIcon />

				<div className="flex flex-col items-center flex-1 p-2 space-y-4">

					<Link to="/home"
						className="p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2"
					>
						{FaHome && <FaHome className="w-5 h-5" />}
					</Link>
				</div>

				<UserImage
					imageUrl="/no-user-image.jpeg"
					label="No User Image"
					onIconClick={toggleUserMenu}
				/>
			</nav>

			<div
				className={`${isSidebarOpen ? "fixed inset-y-0 left-0 z-10 flex-shrink-0 w-[260px] bg-white" : "hidden"
					} border-r-2 shadow-lg sm:left-16 border-primary-100 sm:w-64 lg:static lg:w-[260px]`}
			>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					title="Usuário"
					menuItems={[
						{ href: '/change-password', label: 'Alterar senha', key: "menu-change-password" },
						// { href: '/change-image', label: 'Alterar imagem' },
						// { href: '/change-layout', label: 'Alterar layout' },
					]}
					tabName="tabUser"
					currentSidebarTab={currentSidebarTab}
				/>
			</div>
		</aside>
	);
};

export default Sidebar;
